const token = '6714886384:AAGy6Y-wV8Qom7CoPk9c1uLJEERChe-TkeI'
const administrators = [751639920, -1001982266159]

export function SendMessage(name, tel) {
    const groupId = -1001982266159;
    const messageThreadId = 4644;

    const promises = administrators.map((chatId) => {
        return new Promise((resolve, reject) => {
            let message = `Заявка с сайта❗️%0A%0A👤 Имя: ${name}%0A📞 Телефон: %2B${tel.replace('+', '')}`;


            let url = null
            if (chatId === groupId) {
                url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=${message}&message_thread_id=${messageThreadId}`;
            } else {
                url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=${message}`;
            }


            let xhr = new XMLHttpRequest();
            xhr.open("GET", url, true);

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve('success');
                    } else {
                        reject('error');
                    }
                }
            };

            xhr.send();
        });
    });

    return Promise.all(promises);
}
