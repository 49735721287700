import React, { useState } from "react";
import { SendMessage } from "../../utils/sendMessage"

import "./modal.css"

function Modal(props) {
    const [messageStatus, setMessageStatus] = useState(false)
    const [name, setName] = useState('')
    const [tel, setTel] = useState('+998')


    const changeName = (e) => {
        const inputValue = e.target.value;
        const lettersOnly = /^[A-Za-zА-Яа-яЁё\s]*$/;

        if (lettersOnly.test(inputValue)) {
            setName(inputValue);
        }
    };

    const changeTel = (e) => {
        const inputValue = e.target.value;

        if (inputValue === '') {
            setTel('+998');
            return;
        }

        if (inputValue.startsWith('+998')) {
            const cleanedValue = inputValue.replace(/[^0-9]/g, '');

            if (cleanedValue.length <= 12) {
                const formattedValue = formatPhoneNumber(cleanedValue);
                setTel(formattedValue);
            }
        } else {
            setTel('+998');
        }
    };

    const formatPhoneNumber = (phoneNumber) => {
        const trimmedNumber = phoneNumber.slice(3);
        if (trimmedNumber.length === 0) {
            return '+998';
        }
        const formattedNumber = `+998 ${trimmedNumber.slice(0, 2)} ${trimmedNumber.slice(2, 5)} ${trimmedNumber.slice(5, 7)} ${trimmedNumber.slice(7, 9)}`.trim();
        return formattedNumber.replace(/\s+/g, ' ');
    };;



    function createMessage(e) {
        e.preventDefault()
        if (name !== '') {
            document.querySelector("input[name='name']").classList.remove("input-empty")
            if (tel.replace(/\s+/g, '').length === 13) {
                document.querySelector("input[name='tel']").classList.remove("input-empty")

                if (document.querySelector("input[name='privacy-policy']").checked) {
                    document.querySelector(".modal-privacy-policy p").classList.remove("privacy-policy-warning")
                    SendMessage(name, tel)
                        .then(responses => {
                            const allSuccess = responses.every(response => response === "success");
                            setMessageStatus(allSuccess)
                        })
                        .catch(error => console.log(error));
                } else {
                    document.querySelector(".modal-privacy-policy p").classList.add("input-empty-warning")
                }
            } else {
                document.querySelector("input[name='tel']").classList.add("input-empty")
            }
        } else {
            document.querySelector("input[name='name']").classList.add("input-empty")
        }

    }

    return (
        <div className={props.showModal ? "modal-wrapper modal-wrapper--active" : "modal-wrapper"}>
            <div className="modal-bg" onClick={() => props.setShowModal(false)}></div>
            <div className="modal">
                {messageStatus ?
                    <div className="modal__success-message">
                        Мы получили вашу заявку и в ближайшее время свяжемся с вами :)
                    </div>
                    :
                    <form className="modal-form" action="">
                        <input className="modal-input" name="name" type="text" onChange={changeName} value={name} placeholder="Имя" />
                        <input className="modal-input" name="tel" type="text" onChange={changeTel} value={tel} placeholder="Телефон" />
                        <label className="modal-privacy-policy" htmlFor="rivacy-policy">
                            <input className="modal-privacy-policy__checkbox" type="checkbox" id="rivacy-policy" name="privacy-policy" />
                            <p>
                                Даю своё согласие на обработку моих персональных данных, в соответствии с <a href="/privacy-policy">политикой конфиденциальности</a>*
                            </p>
                        </label>
                        <button type="submit" onClick={(e) => createMessage(e)}>Отправить</button>
                    </form>
                }
            </div>
        </div>
    )
}

export default Modal