import React, { useEffect, useState } from "react";
import "./privacyPolicy.css"
import ReactMarkdown from 'react-markdown';
import PrivacyPolicyText from "./PrivacyPolicy.md"

function PrivacyPolicy() {

    const [markdown, setMarkdown] = useState('');

    useEffect(() => {
        fetch(PrivacyPolicyText)
            .then((response) => response.text())
            .then((text) => setMarkdown(text));
    }, []);


    return (
        <main className="main">
            <section className="privacy-policy-section section-container">
                <h2 className="privacy-policy-section__title">
                    Политика конфиденциальности
                </h2>
                <a className="privacy-policy-section__bread-crumb" href="/">Главная</a>
                <div className="privacy-policy-text__wrapper">
                    <ReactMarkdown>{markdown}</ReactMarkdown>
                </div>
            </section>
        </main >
    )
}

export default PrivacyPolicy